/**
 * A composable for manage password field visibility and related computed properties.
 *
 * @param {boolean} [initialValue=false] - Initial visibility state of the password field.
 * @returns {object} - Returns an object with the following properties:
 *   - `showPasswordText` (Ref<boolean>): Reactive reference to track the password visibility state.
 *   - `appendInnerIconColor` (Computed<string>): Computed property for the color of the append icon based on visibility.
 *   - `passwordFieldType` (Computed<string>): Computed property for the type
 *      of the password input field (`'text'` or `'password'`).
 *   - `passwordFieldAppendIcon` (Computed<string>): Computed property for the icon
 *      to be shown in the append area (e.g., show/hide password icon).
 *   - `togglePasswordVisibility` (Function): Function to toggle the visibility of the password.
 */
export const usePasswordVisibility = (initialValue = false) => {
  const scssVariables = useScssVariables();

  // A ref to track whether the password is currently visible (default: false).
  const showPasswordText = ref(initialValue);

  // Computed property to determine the color of the password visibility toggle icon.
  const appendInnerIconColor = computed(() =>
    showPasswordText.value
      ? scssVariables.jColorTextRegular
      : scssVariables.jColorTextSecondary,
  );

  // Computed property for the password field type ('text' for visible, 'password' for hidden).
  const passwordFieldType = computed(() =>
    showPasswordText.value
      ? 'text'
      : 'password',
  );

  // Computed property for the append icon (show/hide password).
  const passwordFieldAppendIcon = computed(() =>
    showPasswordText.value
      ? 'custom:passwordHideIcon'
      : 'custom:passwordShowIcon',
  );

  /**
   * Toggles the visibility of the password input field.
   * Called when the user clicks the visibility toggle icon.
   */
  const togglePasswordVisibility = () => {
    showPasswordText.value = !showPasswordText.value;
  };

  return {
    showPasswordText,
    appendInnerIconColor,
    passwordFieldType,
    passwordFieldAppendIcon,
    togglePasswordVisibility,
  };
};
